<style scoped>
.listVideos {
  height: 550px;
  overflow: scroll;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Videos",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllVideos();
  },
  data() {
    return {
      title: "Videos",
      videos: [],
      video: null,
      adding_video: false,
      saving_photo: false,
      saving_modification: false,
      transloading_photo: false,
      transloading_video: false,
      saving_video: false,
      forceRegenerateMedia: 1,
      uploadValue: 0,
      uploadValueVideo: 0,
      muscles: [
        "Legs",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
      ],
      niveaux: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      langues: ["it", "fr", "us", "es", "br"],
      items: [
        {
          text: "Videos",
        },
        {
          text: "videos",
          active: true,
        },
      ],
    };
  },
  methods: {
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.video.data.photo.original;

      var data = {
        id: this.video.id,
        url: url,
        type: "videos",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.video.data.photo = result.data.photo;
        firebase.firestore().collection('videos').doc(data.id).update(this.video.data);
        this.transloading_photo = false;
      });
    },
    async transloadVideo(noeud) {
        this.transloading_video = true;

      var video = this.video.data[noeud].original;

      var data = {
        id: this.video.id,
        url: video,
        type: "videos",
        noeud: noeud,
      };

      var transloadVideo = firebase.functions().httpsCallable("transloadVideo", { timeout: 240000 });
      transloadVideo(data).then((result) => {
        // Read result of the Cloud Function.
        this.video.data[noeud] = result.data[noeud];

        firebase
          .firestore()
          .collection("videos")
          .doc(data.id)
          .update(this.video.data);

          this.transloading_video = false;
    
      });
    },
    async transferVideoGoogle(idvideo, chemin, id, index) {
      this.saving_video = true;

      var newPhoto = document.getElementById(id).files[0];
      var type = newPhoto.name.split(".").pop();
      var filename = chemin + "/" + idvideo + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
            this.uploadValueVideo =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("videos")
              .doc(this.video.id)
              .update({
                [index]: {
                  original: url,
                  mobile: null,
                },
              })
              .then(() => {
                this.forceRegenerateMedia++;
                this.video.data[index] = {
                  original: url,
                  mobile: null,
                };
                  this.saving_video = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    async transferPhoto() {
      this.saving_photo = true;
      var newPhoto = document.getElementById("newPhoto").files[0];
      var type = newPhoto.name.split(".").pop();
      var filename = "videos/" + this.video.id + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {

            var newPhoto = {
              original: url,
              optimized: null,
              thumbnail: null,
            };

            var db = firebase.firestore();
            db.collection("videos")
              .doc(this.video.id)
              .update({ photo: newPhoto })
              .then(() => {
                this.forceRegenerateMedia++;
                this.saving_photo = false;
                this.uploadValue = 0;
                this.video.data.photo = newPhoto;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    saveModificationsVideo() {
      //On recupère toutes les valeurs

      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("videos")
        .doc(this.video.id)
        .update(this.video.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewVideo() {
      this.adding_video = true;

      var db = firebase.firestore();
      db.collection("videos")
        .doc(this.video.id)
        .set(this.video.data)
        .then(() => {
          this.adding_video = false;
          this.add_video = false;
          this.videos = [];
          this.video = null;
          this.getAllVideos();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhoto(video) {
      if (video.data.photo.original != null) return video.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoThumb(video) {
      if (video.data.photo.thumbnail != null) return video.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    searchVideo() {
      var search = document.getElementById("searchvideo").value.toLowerCase();
      var muscle = document.getElementById("searchMuscle").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='video']").forEach((element) => {
        var id_video = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let video = this.videos.find((el) => el.id === id_video);
        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (video.data.description.it.nom.toLowerCase().includes(search) ||
            video.data.description.fr.nom.toLowerCase().includes(search) ||
            video.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (video.data.muscles.includes(muscle) || muscle == "all")
        )
          element.style = "display: block";
        else element.style = "display: none";
      });
    },
    getAllVideos() {
      var db = firebase.firestore();
      db.collection("videos")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.videos.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    setVideo(video) {
      this.video = video;
    },
    showAddVideo() {
      var json_video = {
        photo: {
          original: null,
          optimized: null,
          thumbnail: null
        },
        video: {
          original: null,
          mobile: null,
        },
        niveaux: [],
        muscles: [],
        materiels: [],
        difficulte: 5,
        duree: 0,
        kcal: 0,
        description: {
          it: {
            nom: "",
            description: "",
          },
          fr: {
            nom: "",
            description: "",
          },
          us: {
            nom: "",
            description: "",
          },
          es: {
            nom: "",
            description: "",
          },
          pt: {
            nom: "",
            description: "",
          },
        },
      };
      this.video = {
        type: "new",
        id: String(Date.now()),
        data: json_video,
      };
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddVideo()"
              >
                + Add video
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchvideo"
              placeholder="Search video..."
              @keyup="searchVideo()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchVideo()"
              id="searchMuscle"
            >
              <option value="all" selected>All muscles</option>
              <option
                v-for="muscle in muscles"
                :key="'musclesearch' + muscle"
                :value="muscle"
              >
                {{ muscle }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ videos.length }} videos</small>
            </div>
            <div class="listVideos">
              <div
                v-for="(video, key) in videos"
                :key="'video' + key"
                :id="'videoid' + key"
                :data-id="video.id"
                data-type="video"
                @click="setVideo(video)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-md-auto">
                      <img
                        width="60px"
                        :src="getPhotoThumb(video)"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ video.data.description.it.nom }}
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in video.data.description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              video.data.description[name].nom == '' ||
                              video.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="video == null" class="text-center">Select video</div>
            <div v-if="video != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-2">
                    <img
                      width="100%"
                      :src="getPhoto(video)"
                      :key="'photovideo_' + video.id"
                      alt
                      class="img-thumbnail"
                    />
                    <ul>
                      <li><small>Original: <a :href="video.data.photo.original" target="_blank">{{ video.data.photo.original }}</a></small></li>
                      <li><small>Optimized: <a :href="video.data.photo.optimized" target="_blank">{{ video.data.photo.optimized }}</a></small></li>
                      <li><small>Thumbnail: <a :href="video.data.photo.thumbnail" target="_blank">{{ video.data.photo.thumbnail }}</a></small></li>
                    </ul>
                    <div
                      class="d-grid gap-2 mt-2 mb-2"
                      v-if="video.type != 'new'"
                    >
                      <label for="formFile" class="form-label"
                        >Change photo:</label
                      >
                      <input class="form-control" type="file" id="newPhoto" />
                      <progress
                        v-if="saving_photo"
                        id="progress"
                        :value="uploadValue"
                        max="100"
                      ></progress>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @click="transferPhoto()"
                      >
                        <span
                          v-if="saving_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update photo
                      </button>
                      <div v-if="video.data.photo.original != null" class="d-grid gap-2 mt-2 mb-2">
                        <button
                        class="btn btn-info btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @click="transloadPhoto()"
                      >
                        <span
                          v-if="transloading_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Transload photo
                      </button>
                      </div>

                    </div>
                    <hr />
                    <div v-if="video.type == 'new'">
                      Video & Photo will update after creation.
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ video.data.description.it.nom }}
                          </h1>
                          <small>Video #{{ video.id }}</small>
                        </div>
                        <div class="col-4">
                          <button
                            v-if="video.type != 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="saveModificationsVideo()"
                          >
                            <span
                              v-if="saving_modification"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Save modifications
                          </button>
                          <button
                            v-if="video.type == 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="addNewVideo()"
                          >
                            <span
                              v-if="adding_video"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Add Video
                          </button>
                        </div>
                      </div>

                      <hr />
                      <div
                        class="d-grid gap-2 mt-2 mb-2"
                        v-if="video.type != 'new'"
                      >
                        <h3>Video:</h3>
                        <ul>
                          <li>
                            Originale:
                            <a
                              :href="video.data.video.original"
                              target="_blank"
                              >{{ video.data.video.original }}</a
                            >
                          </li>
                          <li>
                            Mobile:
                            <a
                              :href="video.data.video.mobile"
                              target="_blank"
                              >{{ video.data.video.mobile }}</a
                            >
                          </li>
                         
                        </ul>

                        <label for="formFile" class="form-label"
                          >Update vidéo:</label
                        >
                        <input class="form-control" type="file" id="video" />
                        <progress
                          v-if="saving_video"
                          id="progress2"
                          :value="uploadValueVideo"
                          max="100"
                        ></progress>
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="uploadVideo"
                          @click="
                            transferVideoGoogle(
                              video.id,
                              'videos',
                              'video',
                              'video'
                            )
                          "
                        >
                          <span
                            v-if="saving_video"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Update video
                        </button>

                        <div v-if="video.data.video.original != null" class="d-grid gap-2 mt-2 mb-2">
                        <button
                          class="btn btn-info btn-sm"
                          type="button"
                          @click="transloadVideo('video')"
                        >
                          <span
                            v-if="transloading_video"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Transload Video
                        </button>
                        </div>
                      </div>

                      <hr />

                      <div class="container px-0 py-2">
                        <div class="row">
                          <b-tabs content-class="mt-3">
                            <b-tab
                              v-for="(langue, key) in video.data.description"
                              :key="'descriptiontab-' + key"
                              :active="key === 'it'"
                            >
                              <template slot="title">
                                <img
                                  :src="
                                    'https://flagcdn.com/h20/' + key + '.png'
                                  "
                                  height="12"
                                />
                                {{ key }}
                              </template>
                              <div class="mb-3">
                                <label for="add-nom_it" class="form-label"
                                  >Name*</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="'nom_' + key"
                                  v-model="video.data.description[key].nom"
                                />
                              </div>
                              <div class="mb-3">
                                <label
                                  :for="'description_' + key"
                                  class="form-label"
                                  >Description*</label
                                >
                                <textarea
                                  class="form-control"
                                  :id="'description_' + key"
                                  rows="3"
                                  v-model="
                                    video.data.description[key].description
                                  "
                                ></textarea>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="unilaterale"
                            :key="'live_' + video.id"
                            v-model="video.data.live"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Live?
                          </label>
                        </div>
                        <div class="col">
                          <label class="form-label">Difficulté*</label>
                          <small>Entre 1 et 10</small>
                          <input
                            type="number"
                            min="1"
                            max="10"
                            class="form-control"
                            :id="'difficulte_' + video.id"
                            v-model="video.data.difficulte"
                          />
                        </div>
                        <div class="col">
                          <label class="form-label">Duree (en min)</label>
                          <input
                            type="number"
                            min="1"
                            max="200"
                            class="form-control"
                            :id="'duree_' + video.id"
                            v-model="video.data.duree"
                          />
                        </div>
                        <div class="col">
                          <label class="form-label">Kcal</label>
                          <input
                            type="number"
                            min="1"
                            max="2000"
                            class="form-control"
                            :id="'kcal_' + video.id"
                            v-model="video.data.kcal"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <label>Muscles travaillés</label>
                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="video.data.muscles"
                              :options="muscles"
                              name="muscles_video"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>

                        <div class="col">
                          <label>Materiel</label>

                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="video.data.materiels"
                              :options="materiels"
                              name="materiels_video"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <p><label>Niveau</label></p>
                          <p><small>Indiqué TOUS les niveaux adaptés</small></p>

                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="video.data.niveaux"
                              :options="niveaux"
                              name="niveaux_video"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
